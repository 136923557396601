.icard {
    background-color: burlywood;
}

.page {
    padding-top: 20px;

    .icon {
        width: 55px;
        height: 55px;
    }

    .transporter-activity-container {
        border-bottom: 1.5px solid #EBEDEF;

        .transporter-activity-body{
            max-height: 350px;
            min-height: 350px;
            overflow-y: scroll;

            &:hover{
                overflow-y: scroll;
            }
            &::-webkit-scrollbar {
                background-color: transparent;
                width: 5px;
            }
            &::-webkit-scrollbar-track {
                background-color: var(--custom-white);
            }
            &::-webkit-scrollbar-thumb {
                background-color: #D4E6F1 ;
                border-radius: 20px;
                border: 1px solid var(--custom-white);
            }

            .transporter-activity-row {
                border-bottom: 1.5px solid #EBEDEF;
                padding-bottom: 10px;
                padding-top: 10px;

                .avatar {
                    width: 35px;
                    height: 35px;
                    border-radius: 50%;
                    margin-right: 10px;
                }

                .date-title {
                    font-size: 14px;
                }

                .body-text {
                    font-size: 14px;
                    color: #343a40;
                    line-height: 1.6 !important;
                }
            }
        }
    }

    .recent-shipments-container {
        border-bottom: 1.5px solid #EBEDEF;

        a {
            text-decoration: none;
        }

        .recent-shipment-body{
            max-height: 350px;
            min-height: 350px;
            overflow-y: scroll;

            &:hover{
                overflow-y: scroll;
            }
            &::-webkit-scrollbar {
                background-color: transparent;
                width: 5px;
            }
            &::-webkit-scrollbar-track {
                background-color: var(--custom-white);
            }
            &::-webkit-scrollbar-thumb {
                background-color: #D4E6F1 ;
                border-radius: 20px;
                border: 1px solid var(--custom-white);
            }

            .recent-shipment-row {
                border-bottom: 1.5px solid #EBEDEF;
                padding-bottom: 10px;
                padding-top: 10px;

                .content {
                    width: 100%;
                }

                .avatar {
                    width: 35px;
                    height: 35px;
                    border-radius: 50%;
                    margin-right: 10px;
                }

                .name {
                    font-size: 14px;
                    color: #343a40;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    width: 50%;
                }

                .destination {
                    font-size: 14px;
                    line-height: 1.6 !important;
                    color: #74788d;
                }

                .status{
                    padding: 3px 15px 3px 15px;
                    border-radius: 20px;
                    font-size: 12px;
                }

                .Rejected, .Cancelled{
                    color: #924040;
                    background-color: #fde1e1;
                    border-color: #fcd2d2;
                }

                .PendingApproval{
                    color: #464855;
                    background-color: #e3e4e8;
                    border-color: #d5d7dd;
                }

                .PendingPickup{
                    color: #37458b;
                    background-color: #dee3fa;
                    border-color: #ced5f8;
                }

                .Delivered{
                    color: #1f7556;
                    background-color: #d6f3e9;
                    border-color: #c2eddd;
                }

                .PickedUp,  .GoingToPickup, .GoingToDeliver{
                    color: #916c2e;
                    background-color: #fcf0db;
                    border-color: #fbe9c9;
                }
            }
        }
    }

    .recent-transactions-container {
        border-bottom: 1.5px solid #EBEDEF;

        .recent-transactions-body{
            max-height: 365px;
            min-height: 365px;
            overflow: scroll;

            &:hover{
                overflow-y: scroll;
            }
            &::-webkit-scrollbar {
                background-color: transparent;
                width: 5px;
            }
            &::-webkit-scrollbar-track {
                background-color: var(--custom-white);
            }
            &::-webkit-scrollbar-thumb {
                background-color: #D4E6F1 ;
                border-radius: 20px;
                border: 1px solid var(--custom-white);
            }

            .recent-transactions-table {
                width: 100%;

                tr {
                    border-bottom: 1.5px solid #EBEDEF;
                    padding-bottom: 10px;
                    padding-top: 10px;
                    width: 100%;
                }

                td:first-child {
                    //border-top: 1.5px solid #EBEDEF;
                }

                td.description {
                    width: 50%;
                    padding-bottom: 10px;
                    padding-top: 10px;

                    .icon {
                        width: 30px;
                        height: 30px;
                        margin-right: 10px;
                    }

                    .title {
                        font-size: 14px;
                        color: #343a40;
                    }
                }

                td.amount {
                    width: 25%;
                }

                td.status {
                    width: 25%;
                }
            }
        }

        .recent-transaction {
            border-bottom: 1.5px solid #EBEDEF;
            padding-bottom: 10px;
            padding-top: 10px;

            .icon {
                width: 30px;
                height: 30px;
                margin-right: 10px;
            }

            .title {
                font-size: 14px;
                color: #343a40;
                line-height: 1.6 !important;
            }

            .shipment-state {
                background-color: #F2D7D5;
                color: #A93226;
                padding: 0 5px 0 5px;
                border-radius: 3px;
                font-size: 13px;
            }

            .Pending {
                background-color: #FCF3CF;
                color: #f1b44c;
                padding: 0 5px 0 5px;
                border-radius: 3px;
                font-size: 13px;
                font-weight: bold;
            }

            .destination {
                font-size: 10px;
                line-height: 1.6 !important;
            }
        }

    }
}