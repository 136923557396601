.shipper-container{
    .title{
        color: #343a40;
    }

    .sender-name{
        color: #008561;
        cursor: pointer;

        &:hover{
            color: #45B39D;
        }
    }
}

.header{
    font-size: 18px;
    color: #343a40;
}

.status{
    padding: 5px 15px 5px 15px;
    border-radius: 20px;
    font-size: 14px;
    //border: 1px solid;
}

.Rejected, .Cancelled{
    color: #924040;
    background-color: #fde1e1;
    border-color: #fcd2d2;
}

.PendingApproval{
    color: #464855;
    background-color: #e3e4e8;
    border-color: #d5d7dd;
}

.PendingPickup{
    color: #37458b;
    background-color: #dee3fa;
    border-color: #ced5f8;
}

.Delivered{
    color: #1f7556;
    background-color: #d6f3e9;
    border-color: #c2eddd;
}

.PickedUp,  .GoingToPickup, .GoingToDeliver, .PendingDelivery, .InTransit{
    color: #916c2e;
    background-color: #fcf0db;
    border-color: #fbe9c9;
}

.percentText {
    font-size: 14px;
}

.percentProfit {
    color: #1f7556;
}

.percentLoss {
    color: #924040;
}

.carrierAmt {
    font-weight: 600;
}

.order-item-row {
    padding: .5rem 0;
}
  
.order-item-grid {
    display: grid;
    grid-template-columns: repeat(8, minmax(0, 1fr));
}
  
.order-item-header {
    font-weight: 600;
}
  
.first-order {
    grid-column: span 2 / span 2;
}

.shipment-timeline-container{
    margin-top: 20px;
    max-height: 470px;
    min-height: 470px;
    padding-right: 5px;
    overflow-y: scroll;

    &:hover{
        overflow-y: scroll;
    }
    &::-webkit-scrollbar {
        background-color: transparent;
        width: 5px;
    }
    &::-webkit-scrollbar-track {
        background-color: var(--custom-white);
    }
    &::-webkit-scrollbar-thumb {
        background-color: #D4E6F1 ;
        border-radius: 20px;
        border: 1px solid var(--custom-white);
    }
}

.unapproved-ring{
    border: 2px solid #008561;
    width: 16px;
    color: #ffffff;
    border-radius: 50%;
    height: 16px;
    margin-right: 8px;
    margin-top: 7px;

    p{
        margin-top: -2px;
        font-size: 10px;
        text-align: center;
        color: #008561;
    }
}

.approved-ring{
    border: 2px solid #008561;
    background-color: #008561;
    color:white;
    width: 16px;
    border-radius: 50%;
    height: 16px;
    margin-right: 8px;
    margin-top: 7px;

    p{
        margin-top: -2px;
        font-size: 10px;
        color: #ffffff;
        text-align: center;
    }
}