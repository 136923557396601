@import url('https://fonts.googleapis.com/css2?family=Prompt:wght@600&display=swap');

.box-container {
    max-width: 550px;
    margin: 100px auto;
    overflow: auto;

    a {
        text-decoration: none;
    }
}

.input-container {
    background-color: #D4E6F1;
    padding: 15px;
    border-radius: 3px;
}

.login-input {
    width: 100%;
    outline: none;
    border: none;
    background-color: transparent;

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px #D4E6F1 inset !important;
    }

    &:active,
    &:focus,
    &:focus-within {
        background-color: transparent;
    }
}

.login-button {
    background-color: #EE2463;
    height: 45px;
    width: 100%;
    border: 1px solid transparent;
    border-radius: 3px;
    box-shadow: rgba(255, 255, 255, .4) 0 1px 0 0 inset;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-family: -apple-system, system-ui, "Segoe UI", "Liberation Sans", sans-serif;
    font-size: 13px;
    font-weight: 400;
    line-height: 1.15385;
    margin: 0;
    outline: none;
    padding: 8px .8em;
    position: relative;
    text-align: center;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: baseline;
    white-space: nowrap;

    &:hover{
        background-color: #252229;
        box-shadow: 0 0 0 4px rgba(0, 149, 255, .15);
    }
}